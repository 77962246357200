<template>
    <div class="main auto-height">
        <div class="xinxbox">
            <Row type="flex" justify="space-around" class="code-row-bg">
                <Col span="6" push="7" class-name="zixing" @click.native="shouye()">首页</Col>
                <Col span="6" push="2"   class-name="zixing">>信息公开</Col>
                <Col span="6"  class-name="zi">>{{typename}}</Col>
            </Row>
            <div class="tabs">
                <div class="one">
                  <img class="noe-tu" src="../../../assets/img/xinx/分类@2x.png" alt="">
                    <div class="one-zi">列表导航</div>
                </div>
                <div class="two1"   @click="jianjie()" :style="{ background: num == 1 ? 'rgba(45, 140, 240, 1)' : '#fff', color: num == 1 ? '#ffffff' : 'rgba(0, 92, 176, 1)',}">
                  <img class="noe-tu1" src="../../../assets/img/xinx/机构简介@2x.png"  alt="" ref="noetu1" :style="{display: num == 1 ? 'block' : 'none'}">
                  <img class="noe-tu2" src="../../../assets/img/xinx/机构简介蓝色@2x.png"  alt="" ref="noetu2" :style="{display:num==1?'none':'block'}">

                    <div class="two-zi" ref="two-zi">机构简介</div>
                </div>
                <div class="two2"  ref="two2" @click="shezhi()" :style="{ background: num == 2 ? 'rgba(45, 140, 240, 1)' : '#fff', color: num == 2 ? '#ffffff' : 'rgba(0, 92, 176, 1)',}">
                      <img class="noe-tu1" src="../../../assets/img/xinx/设置白色@2x.png"  alt="" ref="noetu3" :style="{display:num== 2 ? 'block':'none'}">
                      <img class="noe-tu2" src="../../../assets/img/xinx/设置@2x.png"  alt="" ref="noetu4" :style="{display: num== 2 ?  'none':'block'}">
                
                    <div class="two-zi">机构设置</div>
                </div>
                <div class="two3" @click="lianxi" :style="{ background: num == 3 ? 'rgba(45, 140, 240, 1)' : '#fff', color: num == 3 ? '#ffffff' : 'rgba(0, 92, 176, 1)',}">
                  <img class="noe-tu1" src="../../../assets/img/xinx/电话白色@2x.png"  alt=""  ref="noetu5" :style="{display:num== 3 ? 'block':'none'}">
                  <img class="noe-tu2" src="../../../assets/img/xinx/电话@2x.png" alt=""  ref="noetu6" :style="{display: num== 3 ?  'none':'block'}">
                    <div class="two-zi">联系我们</div>
                </div>
                <div class="two4" @click="gong()" :style="{ background: num == 4 ? 'rgba(45, 140, 240, 1)' : '#fff', color: num == 4 ? '#ffffff' : 'rgba(0, 92, 176, 1)',}">
                  <img class="noe-tu1" src="../../../assets/img/xinx/通知管理白色@2x.png"  alt=""  ref="noetu7" :style="{display:num== 4 ? 'block':'none'}" >
                  <img class="noe-tu2" src="../../../assets/img/xinx/通知管理@2x.png"  alt=""  ref="noetu8" :style="{display: num== 4 ?  'none':'block'}">
                    <div class="two-zi">公告通知</div>
                </div>
            </div>
            <div class="jigoubox" v-show="num == 1">
                <img src="../../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                <div class="ziti">机构简介</div>
                <div class="hengxian"></div>
                <div  class="contee" >
                    <h1 class="h1" v-for="(item,i) in arr1" :key="i">{{item}}</h1>
                    <div v-html="this.jigoujianjie.content" class="conre"></div>
                </div>
         
               
            </div>
           
          <div class="jigoubox" v-show="num == 2">
                <img src="../../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                <div class="ziti">机构设置</div>
                <div class="hengxian"></div>
                 <div class="bangs" v-for="(item,index) in jigoushezhi" :key="index">
                    <div class="shehziq"> {{item.divisionName}}</div>
                    <div class="bangsjsa">
                         <div class="bangsjs" v-html="item.divisionIntro"></div>
                    </div>
                   
                </div>
            </div>
             <div class="jigoubox" v-show="num == 3">
                <img src="../../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                <div class="ziti">联系我们</div>
                <div class="hengxian"></div>
                    <div class="zjs" v-for="(item,i) in arr" :key="i">{{item}}</div>
                <div class="zixun" @click="zixun()">
                    <img src="../../../assets/img/xinx/客服 (1)@2x.png" alt="" class="kefu">
                    <div class="zixu">咨询留言</div>
                </div>
                <div class="tel">
                    <div>电话：{{this.lianxiwomen.mobile}}</div>
                    <div>邮编：{{this.lianxiwomen.postCode}}</div>
                    <div>地址：{{this.lianxiwomen.organizationAddress}}</div>
                </div>
                <!-- 百度地图 -->
                <div id="allmap">
                    <img @click="gobaidu()" class="baidu" src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1623226697411.jpg" alt="">
                </div> 
              
            </div>
            <div class="jigoubox" v-show="num == 4">
                <img src="../../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                <div class="ziti">公告通知</div>
                <div class="hengxian"></div>
                <div class="tongzhi" v-for="(item,index) in gonggao" :key="index">
                    <div class="biaoti"  @click="leix(item.id)">{{item.newsTitle}} </div>
                    <div class="shijian">{{item.createTime }}</div>
                </div>
               
                        <!-- 分页 -->
                  <el-pagination class="fenye"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[15]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount">
                   </el-pagination>
            </div>
            
        </div>
    </div>
</template>

<script>
import {incrReadingNum,queryPageOrganization,queryPageOrganizationIntro,queryPageContact,queryPageNews} from "../../../woke/xinx"
import { formatDate } from '../../../commm/time'


export default {
   components:{},
    data(){
        
        return{
            num:1,
            jigoujianjie:[],
            jigoushezhi:[],
            lianxiwomen:[],
            gonggao:[],
            createTime:"",
            divisionIntro:"",
            divisionName:"",
            content:"",
             //机构名称
            organizationName:"",
            //经度
            // latitude:120.150464,
            latitude:"",
            //维度
            // longitude:30.291626,
            longitude:"",
            //电话
            mobile:"",
            //邮编
            postCode:"",
            //地址
            organizationAddress:"",
            typename:"",
            
            totalCount:null,//总数据
            currentPage:1,//初始页
            pageSize: 15 ,//每页显示条目数
             arr:[],
             arr1:[],
        }
    },
    created(){
        //最后需要在mounted钩子里面调一次你自己定义的方法
        this.queryPageOrganization()
        this.queryPageOrganizationIntro()
        this.queryPageContact()
        this.queryPageNews() 
        if(this.num == 1){
            this.typename = "机构简介" 
        } else if(this.num==2){
            this.typename = "机构设置"
        } else if(this.num == 3){
            this.typename = "联系我们"
        } else{
            this.typename = "公告通知"
        }
    },
   
    mounted() {
        
    },
    activated(){
        // this.myMap();
    },
    methods:{
        gobaidu(){
             
            window.open("http://api.map.baidu.com/marker?location=30.291533,120.150701&title=浙江省药品信息宣传和发展服务中心(浙江省药品监督管理局行政受理中心)&output=html&src=webapp.baidu.openAPIdemo","_blank")
        },
         //改变分页的每页的页数
     handleSizeChange(size) {
        //  console.log(size);
       this.pageSize = size
        this.queryPageNews()
    //    console.log(this.pageSize) //每页下拉显示数据
     },
     // 改变分页的当前页面
     handleCurrentChange(currentPage) {
        //  console.log(currentPage);
       this.currentPage = currentPage
        this.queryPageNews()
    //    console.log(this.currentPage) //点击第几页
     },
     

 

        shouye(){
            this.$router.push("/index")
        },
        zixun(){
            return
            this.$router.push("/zixun")
        },
         leix(id){
             
        //    console.log(newsTitle,createTime,newsResource,content,accessoryUrl, accessoryName);
        console.log(id);
            incrReadingNum({
                id:id,
                type:1
            }).then(res=>{
                console.log(res);
            })
               this.$router.push({
               name:"ggdetail",
               params:{
                      id:id
                }
            });
          
      },
        //机构设置
        queryPageOrganization(){
            queryPageOrganization({
                divisionIntro:this.divisionIntro,
                divisionName:this.divisionName
            }).then((res=>{
                // console.log(res,"机构设置");
                this.jigoushezhi=res.data.data
            }))
        },
        //机构简介
        queryPageOrganizationIntro(){
            queryPageOrganizationIntro({
                content:this.content,
                organizationName:this.organizationName
            }).then((res)=>{
                // console.log(res.data.data[0],"机构简介");
                this.jigoujianjie = res.data.data[0]
                this.arr1=this.jigoujianjie.organizationName.split("#")

            })
        },
        //查询联系我们
        queryPageContact(){
            queryPageContact({
                organizationName:this.organizationName,
                mobile:this.mobile,
                postCode:this.postCode,
                organizationAddress:this.organizationAddress,
                // latitude:this.latitude,
                // longitude:this.longitude,
            }).then(res=>{
                // console.log(res,"查询联系我们");
                this.lianxiwomen=res.data.data[0]
                // console.log(res.data.data[0].latitude);
                this.latitude = res.data.data[0].latitude
                this.longitude = res.data.data[0].longitude
                this.arr= this.lianxiwomen.organizationName.split("#")

            })
        },
        //公告
        queryPageNews(){
             queryPageNews({
                curPage:this.currentPage,
                maxPage:this.pageSize,
                isStick:3,
                type:3
            }).then(res=>{
                // console.log(res.data.data,"公告")
                this.gonggao = res.data.data.data;
                this.totalCount = res.data.data.total;
                
                
            })
        },  
        // myMap() {
        //         // console.log('111111111')
        //         let map = new BMapGL.Map("allmap"); // 创建Map实例 注意要和你上面写的div的id名一样
        //         let point = new BMapGL.Point( this.longitude,this.latitude, );   // 创建坐标点
        //         map.centerAndZoom(point, 25); // 初始化地图，设置中心点坐标和地图级别
        //         map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放，
        //         map.setCenter(point);//point是设置的中心点
        //         map.addEventListener("tilesloaded",function(){//mapName是你的地图名称
        //            map.setCenter(point);//point是设置的中心点
        //         });
        //         let marker = new BMapGL.Marker(new BMapGL.Point(this.longitude,this.latitude,)); //创建标记
        //         marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
        //         // 在地图上添加点标记
        //         map.addOverlay(marker);
        //         let opts = {
        //             width: 250,
        //             height: 20,
        //         };
        //         let infoWindow = new BMapGL.InfoWindow('浙江省药品监督管理局行政受理中心', opts);
        //         map.openInfoWindow(infoWindow, point);
        //         marker.addEventListener("click", function(){          
        //             map.openInfoWindow(infoWindow, point); //开启信息窗口
        //         }); 
        //         map.setViewport(point);
        //         map.addEventListener('click', function () {
        //               window.open( "https://map.baidu.com/poi/%E6%B5%99%E6%B1%9F%E7%9C%81%E5%8C%BB%E8%8D%AF%E7%BB%8F%E6%B5%8E%E5%8F%91%E5%B1%95%E4%B8%AD%E5%BF%83/@13375250.931156823,3519333.749567673,21z?uid=991493323959147f5b10931c&info_merge=1&isBizPoi=false&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl" ,"_blank")
        //         });
        //     },
         jianjie(){
             this.num = 1  
             if(this.num == 1){
                this.typename = "机构简介" 
            } else if(this.num==2){
                this.typename = "机构设置"
            } else if(this.num == 3){
                this.typename = "联系我们"
            } else{
                this.typename = "公告通知"
            }
         },
        shezhi(){
             this.num = 2
             if(this.num == 1){
            this.typename = "机构简介" 
        } else if(this.num==2){
            this.typename = "机构设置"
        } else if(this.num == 3){
            this.typename = "联系我们"
        } else{
            this.typename = "公告通知"
        }
         },
         lianxi(){
               this.num = 3
            //    this.myMap(); 
               if(this.num == 1){
            this.typename = "机构简介" 
        } else if(this.num==2){
            this.typename = "机构设置"
        } else if(this.num == 3){
            this.typename = "联系我们"
        } else{
            this.typename = "公告通知"
        }
         },
         gong(){
              this.num = 4
              if(this.num == 1){
            this.typename = "机构简介" 
        } else if(this.num==2){
            this.typename = "机构设置"
        } else if(this.num == 3){
            this.typename = "联系我们"
        } else{
            this.typename = "公告通知"
        }
         },
         
    },
     
    filters:{
       /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/ 
    formatDate(time) {
        var date = new Date(time);
        return formatDate(date, 'yyyy-MM-dd'); 
    },
    },
     watch: {
         
      $route( to , from ){   
          console.log($route);
        if(to.path!=from.path){
            this.num = 4
            
        }
     }
  },

}
</script>
<style scoped>
.auto-height{
    min-height:100vh;
    margin-bottom:-60px;
    box-sizing:border-box;
    padding-top:30px;
    padding-bottom:60px;
    margin-left: 30px;
}
.code-row-bg{
    width: 350px;
    margin-left: 262px;
    margin-bottom: 20px;
}
.zixing{
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    cursor: pointer;
}
.zi{
     margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304C87;
    line-height: 21px;
}
.el-menu-vertical-demo{
    margin-top: 40px;
    margin-left: 400px;
}
.tabs{
    width: 202px;
    height: 300px;
    border: 1px solid #005cb0;
    margin-left: 383px;
    margin-top: 20px;
    /* border-radius: 5px; */
}
.one{
    width:200px;
    height: 60px;
    background-color: #005cb0;
    text-align: center;
    /* margin-left: 80px; */
    display: flex;
    justify-content: center;
    line-height: 60px;
    padding: 20px;
    
}
.noe-tu{
   width: 17px;
   height: 17px;
   margin-top: 3px;
   margin-left: 13px;
}
.one-zi{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 20px;
    margin-left: 10px;
}
.two-zi{
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    line-height: 24px;
    margin-left: 11px;
}

.two1{
    width:200px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 60px;
    padding: 20px;
    border-bottom: 1px solid #005CB0FF;
    cursor: pointer;
}
.two2{
    width:200px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 60px;
    padding: 20px;
    border-bottom: 1px solid #005CB0FF;
    cursor: pointer;
}
.two3{
    width:200px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 60px;
    padding: 20px;
    border-bottom: 1px solid #005CB0FF;
    cursor: pointer;
}
.two4{
    width:200px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 60px;
    padding: 20px;
    border-bottom: 1px solid #005CB0FF;
    cursor: pointer;
}
.jigoubox{
    width: 100px;
    margin-left: 700px;
    margin-top: -300px;
}
.hengxian{
     margin-top: 20px;
     width:859px;
     height:1px;
     background-color:#005CB0;
     overflow:hidden;
    /* margin-left: 100px; */
}
.ziti{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2C3B5B;
    line-height: 24px;
    letter-spacing: 2px;
    display: inline-block;
    margin-left: 10px;
}
.tibiao{
    width: 10px;
    height: 16px;
    margin-top: -5px;
}
.bangs{
    margin-top: 20px;
    width: 859px;
    height: 120px;
    background: #F5FAFF;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    
     
}
.shehziq{
    width: 144px;
    background: #005CB0;
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 120px;
    text-align: center;
   
}
.bangsjs{
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #1A1A1A;
    flex-wrap: wrap;
    line-height: 30px;
    display: table-cell; 
    vertical-align:middle; 
    text-align:left;
}
.bangsjsa{
      width: 715px;
    display: table;
     height: 120px;
     margin-left: 20px;
}
.tongzhi{
    width: 860px;
    display: flex;
    justify-content: space-between;
     margin-top: 5px;
    cursor: pointer;
}
.biaoti{
    font-size: 16px;
    width: 720px;
    font-family: MicrosoftYaHei;
    color: #2C3B5B;
    line-height: 21px;
    overflow: hidden;
    text-overflow:ellipsis; 
    white-space: nowrap;
    margin-bottom: 10px;
}
.shijian{
    font-size: 14px;
font-family: MicrosoftYaHei;
color: #808080;
line-height: 19px;
}
.fgenye{
    width: 600px;
    margin-top: 500px;
    margin-left: 150px;
}
.zjs{
    width: 700px;
    font-size: 24px;
font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
font-weight: bold;
color: #1A1A1A;
line-height: 31px;
margin-top: 10px;
text-align: center;
display: inline-block;
}
.yaojian{
    width: 700px;
    font-size: 20px;
font-family: MicrosoftYaHei;
color: #808080;
line-height: 26px;
text-align: center;
display: inline-block;
}
.tel{
    width: 320px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 30px;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 200px;
    margin-top: -50px;
}
.zixun{
    opacity: 0;
    width: 110px;
    height: 110px;
    background: #F7F7F7;
    display: inline-block;
    text-align: center;
    line-height: 70px;
    margin-left: 580px;
    margin-top: -20px;
    cursor: pointer;
}
.zixu{
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #005CB0;
    line-height: 30px;
    margin-top: -10px;
}
.contee{
    width: 870px;
    margin-bottom: 328px;
}
.h1{
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
    /* margin-left: 100px; */
}
 #allmap {
     width: 700px;
     height: 400px;
     z-index: 500;
 }
 .conre{
     font-size: 15px;
 }
 .noe-tu2{
      width: 17px;
   height: 17px;
   margin-top: 3px;
  
 }
 .noe-tu1{
      width: 17px;
   height: 17px;
   margin-top: 3px; 
 
  
 }
 .fenye{
     width: 600px;
     margin-top: 50px;
     margin-left: 418px;
 }
 .kefu{
     width: 36px;
     height: 39px;
 }
</style>